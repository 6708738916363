.overview-container {
  height: 60rem;
}
.overview-techtemplate-modal {
  border: .1rem solid $darkerGrey;
  box-sizing: border-box;
  box-shadow: 0 0 .8rem rgba(0, 0, 0, 0.4);
  border-radius: .8rem;
  padding: 0;
  background: $mineShaft;
  .elements-label {
    width: 100%;
    font-size: 1.6rem;
  }
}
.overview-techtest-left {
  border-right: 0.1rem solid $darkerGrey;
  width: 56%;
  padding: 4.1rem 4.5rem .5rem;
}
.overview-techtest-right {
  display: flex;
  flex-direction: column;
  width: 44%;
  padding: 4.1rem 4.5rem .5rem;
}
.ant-row .overview-techtest-title {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 3.1rem;
}
.ant-row .overview-techtest-title.overview-candidate-summary {
  margin-bottom: 1.3rem;
}
.ant-row .overview-techtest-candidate {
  margin: 1.3rem 0 4rem;
  font-size: 1.8rem;
  font-weight: 400;
}
.ant-row .overview-techtest-finalnote {
  margin-top: .9rem;
  border: .1rem solid rgba(242, 242, 242, 0.12);
  border-radius: .8rem;
  width: 100%;
  height: 12rem;
  padding: .8rem 1.2rem;
  background-color: $veryDarkGrey;
  font-size: 1.6rem;
  font-family: 'SF Pro Text', sans-serif;
  line-height: 2.4rem;
  color: $white;
  resize: none;
  &--results {
    height: 33rem;
  }
}
.overview-techtest-date {
  font-size: 1.8rem;
  .right-align {
    display: block;
    text-align: right;
    .overview-techtest-time {
      color: $hedgehog;
    }
  }
}
.ant-col .overview-category {
  width: 100%;
  .overview-question-level {
    margin-bottom: .2rem;
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    &-step_one {
      background-color: $lightGreen;
    }
    &-junior {
      background-color: $warning;
    }
    &-professional {
      background-color: $java;
    }
    &-senior {
      background-color: $monaLisa;
    }
  }
  .overview-question-title {
    max-width: calc(100% - 5.4rem);
    padding: 0 1rem;
  }
}
.ant-row .overview-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem 0;
  border: 0.1rem solid $darkerGrey;
  border-radius: 0.8rem;
  height: 5.6rem;
  padding:  1.2rem;
  background: $veryDarkGrey;
  font-size: 1.8rem;
  font-weight: 500;
  &--cursor {
    cursor: pointer;
  }
}
.overview-questions-container {
  overflow-y: scroll;
  height: 37.4rem;
}
.overview-questions-container::-webkit-scrollbar {
  display: none;
}
.overview-techtest-buttons {
  display: flex;
  justify-content: flex-end;
  align-content: end;
  flex-grow: 1;
  gap: 1rem;
  padding-bottom: 2rem;
}
.ant-col .custom-button--submit > span {
  font-family: 'SF Pro Text', sans-serif;
}
.overview-question-modal {
  .overview-question-container {
    position:relative;
    height: 100%;
    padding: 3.8rem 4.5rem;

    .elements-label {
      margin: 4rem 0 1.4rem;
      width: 100%;
      font-size: 1.6rem;
    }

    .question-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &--margin {
        margin-bottom: 2.4rem;
      }

      &--title {
        margin: 0;
        font-weight: 500;
        font-size: 1.8rem;
      }

      &--level {
        font-family: 'SF Pro Text', sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        span {
          width: 1.4rem;
          height: 1.4rem;
          font-family: 'SF Pro Text', sans-serif;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }

      &--question-text {
        max-width: 90%;
        font-size: 1.8rem;
      }

      &--button {
        position: absolute;
        bottom: 3.8rem;
        right: 4.5rem;
      }
      &--note {
        overflow: scroll;
        height: 20rem;
        font-size: 1.8rem;
      }
    }
    .ant-row .level .level-dot {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}