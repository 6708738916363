.test-question-container__file {
  @include flex($justify: center, $align: center);
  width: 100%;
  height: 300px !important;
}
.test-question-container {
  width: 72rem;
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  background-color: $dark;

  .radio-answer-group {
    font-family: 'SF Pro Text', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    width: 100%;

    .ant-checkbox-inner {
      border-color: rgba(255, 255, 255, 0.85);
    }

    .answer-container {
      width: 100%;
      min-height: 10rem;
      padding: 1rem;
      border: 0.5px solid $secondary;
      border-radius: 0.8rem;
      background-color: $veryDarkGrey;
      margin: 0.5rem 0;
      font-size: 1.6rem;
      color: $gallery;

      .answer {
        margin-top: 2rem;
        font-size: 1.6rem;
      }
    }

    .test-answer.active {
      border-color: $success;
    }
  }

  &__selectAnswer {
    @include fontStyles(1.6rem, $normal);
    color: $gallery;
    margin: 5.8rem 0;
  }

  &__form {
    margin-top: 1.7rem;
    height: calc(100% - 18rem);

    &--text-area {
      height: 16rem;
      max-height: calc(100% - 18rem);
      border-radius: $radius-sm;
      border: none;
    }

    &--closed {
      height: auto;
      max-height: calc(100% - 18rem);

      @include flex($justify: space-between);
    }
  }

  .question-content {
    background-color: $veryDarkGrey;
    border: 1px solid $secondary;
    border-radius: 0.8rem;
    margin-bottom: 2rem;
    padding: 1.7rem 0;
    width: 100% !important;

    &__question-title {
      @include fontStyles(1.2rem, $normal);
      padding-left: 1.9rem;
      color: $silverChalice;
    }

    &__question-instructions {
      @include fontStyles(1.6rem, $normal);
      line-height: 2.4rem;
      padding: 1.7rem 3.6rem 0 1.9rem;
    }
  }

  .quizz-timer {
    text-align: center;
    height: 10rem;
    background-color: $veryDarkGrey;
    border: 0.5px solid $secondary;
    border-radius: 0.8rem;
    z-index: 2;
    position: absolute !important;
    right: 32px;
    top: 25px;
    width: 200px;

    .quizz-timer-title {
      font-size: 1.3rem;
      padding: 0.9rem;
      border-radius: 4px 4px 0 0;
      background-color: $tundora;
      color: rgba(255, 255, 255, 0.7);
      font-weight: bold;
      letter-spacing: 0.4px;
    }

    .quizz-timer-subTitle {
      margin: 0.8rem 0.4rem;
      color: rgba(251, 251, 251, 0.6);
      font-weight: bold;
      letter-spacing: 0.4px;
    }

    .quizz-timer-value {
      font-size: 2.4rem;
      color: $niagara;
      margin-bottom: 1rem;
    }
  }

  .unselectable {
    height: 100vh;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .button-container {
    width: 100%;
    height: 10rem;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: $primaryBackgroud;
    justify-content: space-between;
    margin: 0;

    .progress-container {
      @include flex(center, space-between);
      margin: 0;
      &__title {
        display: block;
        text-overflow: unset;
        font-size: 2rem;
        padding: 0 4rem;
        color: $gallery;
      }

      &__row-with-choose-questions {
        margin: 0 auto;
      }

      &.ant-row {
        justify-content: center;
        flex-wrap: nowrap;
        width: calc(100% - 15rem);
        margin: 0;
      }

      .ant-col {
        display: flex !important;
        padding: 0 !important;
        flex: 0 0 auto;
        .ant-list-item {
          display: flex;
          margin-bottom: 0;
        }
      }

      &__arrow {
        font-size: 1.6rem;
        @include flex;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        margin: 1rem;
        z-index: 2;
        background: $tundoraLigh;
        border: solid 2px #7b7b7b;
        border-radius: 0.8rem;

        &:hover {
          background-color: $tundoraLigh;
          color: #bdbdbd;
          cursor: pointer;
        }
      }

      &__question-number {
        @include flex(center, center);
        width: 4rem;
        height: 4rem;
        font-size: 1.6rem;
        margin: 50% 2rem;
        border: 1px solid rgba(242, 242, 242, 0.12);
        background-color: $veryDarkGrey;
        border-radius: 0.8rem;
        color: rgba(240, 240, 240, 0.4);

        &:hover {
          background-color: $tundoraLigh;
          color: rgba(255, 255, 255, 0.8);
        }

        &.selected {
          border: solid 2px $primary;
        }

        &.solved::after {
          content: url('../../assets/icons/correct.svg');
          background-color: $mountainMeadowL;
          border: solid 2px $mountainMeadowL;
          border-radius: 0.8rem;
          color: $veryDarkGrey;
          width: 4rem;
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          left: 0;
          top: 0;
        }

        &.solved:hover {
          background-color: $brightTurquoise;
          border-color: $brightTurquoise;
          color: $veryDarkGrey;
        }

        &.solved:hover::after {
          display: none;
        }

        &.unsolved {
          color: $brightSun;
          border-color: $brightSun;

          &:hover {
            background-color: $tundoraLigh;
          }
        }
      }

      &__status {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 2rem;
        color: #b7b7b7;
      }

      &__progress-bar {
        .ant-progress-bg {
          height: 1.6rem !important;
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: flex-end;
      padding-right: 4rem;
    }

    .ant-radio-inner {
      border-color: rgba(255, 255, 255, 0.85);
    }

    .ant-checkbox-inner {
      border-color: rgba(255, 255, 255, 0.85);
    }

    .language-javascript {
      @include flex;
    }

    .full-width {
      width: 100%;
      margin-bottom: 0;
    }

    .open-answer-container {
      height: 16rem;
      margin-left: 4.9rem;

      .CodeMirror {
        padding: 0.8rem 1.2rem 0 1.2rem;
        height: 16rem;
        border-radius: $radius-sm;
        background-color: $veryDarkGrey;

        .CodeMirror-gutter {
          background-color: $veryDarkGrey;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

.confirmation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 10rem);
  background-color: rgba(22, 22, 22, 0.9);
  z-index: 2;
  &__popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 4rem;
    bottom: 12rem;
    width: 37.2rem;
    height: 24rem;
    background-color: #242424;
    border: solid 1px $darkerGrey;
    border-radius: 0.8rem;
    padding: 2.2rem 2.7rem;
    &-info {
      font-size: 1.8rem;
      text-overflow: unset;
      white-space: normal;
      line-height: 2.15rem;
    }
  }
}

.finish-warning {
  background-color: $codGray;
  &__image {
    height: 7rem;
    width: 7rem;
  }
  &__paragraph {
    white-space: normal !important;
    margin: 2rem auto;
    font-size: 1.8rem;
  }
  &__buttons {
    width: 100%;
    @include flex(center, space-between);
    &-cancel,
    &-finish {
      height: 4rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      color: $porcelain;
      padding: 0.8rem 1.2rem;
    }
    &-cancel {
      background-color: $tundoraLigh;
      &:hover {
        background-color: $scorpion;
      }
    }
    &-finish {
      background-color: $danger;
      &:hover {
        background-color: $seaPinkHover;
      }
    }
  }

  .ant-modal-body {
    width: 36rem;
    height: 38rem;
    padding: 4rem;
    @include flex(center, space-between);
    flex-direction: column;
  }
}

.answer-checkbox {
  margin-top: 1rem !important;
}

.answer-image {
  margin-top: 2rem !important;
}

.test-container {
  width: 100vw;
  background-color: $dark;
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  height: calc(100vh - 100px);
  min-height: 90vh;
  overflow-y: scroll;
}

.reloadTooltip {
  .ant-tooltip-inner {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    border: solid 1px $tundora;
    border-radius: 0.8rem;
    transform: translateX(0);
    width: 28rem;
    .ant-tag {
      margin: 5px 0;
      font-size: 1.2rem !important;
      line-height: 2rem !important;
      padding: 1px 8px !important;
      &:hover {
        opacity: 1;
      }
    }
  }
}
