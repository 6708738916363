.markdown-preview {
  // THE BEGINNING OF STYLING CUSTOM MARKDOWN, AWAITS FOR BRUNO CALL
  p {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  div {
    border-radius: 8px !important;
    margin: 0 !important;
  }

  div code {
    font-size: 1.6rem !important;
  }

  div pre {
    border: 1px solid #454545 !important
      ;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em !important;
    font-weight: bold !important;
  }
}
