.share-template-modal-container {
  .ant-modal-content {
    width: 560px !important;
  }

  &__heading {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 2.4rem;
  }

  &__text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5.3rem !important;
  }

  &__button {
    margin-top: 14.1rem;
    span {
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    }
  }
}