.results-modal {
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
  &__content-container {
    width: 100%;
    height: 100%;
    font-family: 'SF Pro Display', sans-serif;
    color: $primeWhite;
    @include fontStyles(1.8rem);
  }

  .content-container {
    &__left-section {
      padding: 4rem 3.8rem 0 4rem;
      width: 54%;
      height: 100%;
      text-align: left;
    }
    &__right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 46%;
      padding: 4rem 4.1rem 3.8rem 3.6rem;
      border-left: 0.1rem solid $darkerGrey;

      .additional-info {
        text-align: right;
        &__icon {
          @include flex-center;
          margin-left: 2rem;
          font-size: 2.7rem;
        }
        &__text {
          &--green {
            color: $lightGreen;
          }
          &--grey {
            color: #878787;
          }
        }

        &__rate-text {
          margin-right: 0.7rem;
        }
      }

      .additional-info > * {
        margin-bottom: 5rem;
      }
    }

    .left-section {
      &__test-title {
        font-size: 1.8rem;
        margin-right: 2rem;
      }

      &__initial-info {
        margin-top: 3rem;
      }

      &__test-status {
        @include flex-center;
        padding: 0.1rem 0.8rem;
        font-family: 'SF Pro Text', sans-serif;
        font-size: 1.2rem !important;
        font-weight: 400;
        background: inherit !important;
        border-radius: 0.2rem;
        &--yellow {
          color: $primeYellow;
          border: 0.1rem solid $primeYellow;
          font-size: 1.2rem;
        }
        &--red {
          color: $paleRed;
          border: 0.1rem solid $paleRed;
          font-size: 1.2rem;
        }
        &--green {
          color: $lightGreen;
          border: 0.1rem solid $lightGreen;
          font-size: 1.2rem;
        }
      }
    }
    .right-section {
      &__aditional-info {
        text-align: right;
      }

      &__text-container > * {
        margin-bottom: 5rem;
      }
    }
    .initial-info {
      &__text {
        color: #707070;
        font-size: 1.6rem;
        margin-bottom: 1rem !important;
      }
    }

    .cards-container {
      margin-top: 3rem;
      max-height: 41rem;
      overflow-y: scroll;
      &__card {
        height: 5.6rem;
        width: 10.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.8rem 2.2rem;
        background: $veryDarkGrey;
        border: 0.1rem solid $darkerGrey;
        border-radius: 0.8rem;
        margin-right: 1.4rem;
      }
      .card {
        &__counter {
          font-weight: bold;
        }
      }
      .card:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
}

.red {
  color: $paleRed;
}

.yellow {
  color: $primeYellow;
}

.green {
  color: $lightGreen;
}

.languages-container {
  height: 5rem;
  overflow-y: scroll;
}

.languages-container > * {
  margin-bottom: 2rem;
}
