@mixin container-item($borderColor: $darkerGrey) {
  background: $mineShaftDarker;
  border: 0.1rem solid $borderColor;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.4);
}

.template-view-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.template {
  width: 100%;
  max-width: 90rem;
  margin: 4rem 0 5.8rem;

  &__info {
    width: 94%;
    height: 8rem;
    @include container-item;
  }
  &__action {
    margin-top: 2.4rem;
    width: 100%;
    @include container-item;
  }
  .info {
    padding: 1.5rem;
    &__template-title {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 1rem;
    }
    &__department-text {
      font-weight: 400;
      font-size: 1.4rem;
      color: rgba(254, 254, 254, 0.6);
    }
  }

  .category-form {
    width: 100%;
    &__items-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1.6rem 1.6rem 0;
      margin: 2.4rem 0 4rem 0;
      @include container-item;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 0.5rem;

      &--full-width {
        width: 100%;
      }

      &--width {
        width: 70%;
      }
    }

    &__label {
      text-align: left;
      opacity: 0.6;
      font-size: 1.4rem;
      color: $light;
    }

    &__input {
      padding: 0.8rem 1.2rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: $light;
      letter-spacing: 0.2px;
      background: $CharlestonGreen;
      border-radius: $main-radius;

      &--title {
        max-width: 74rem;
      }
    }

    &__question-container {
      margin: 1.6rem;
      width: 94%;
      padding: 1.6rem;
      @include container-item($primary);
    }
  }

  .ant-form-horizontal .ant-form-item-label {
    text-align: left;
  }

  .ant-form label {
    font-size: 1.6rem;
  }

  .ant-radio-wrapper {
    margin-right: 2.5rem;
    display: flex;
    align-items: center;
  }

  .ant-radio-wrapper span {
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 400;
  }
  span.ant-radio + * {
    padding-left: 1.2rem;
  }

  .ant-radio-group {
    display: flex;
  }
}

.blue-icon-container {
  @include flex-center;
  margin-right: 1.6rem;
  line-height: 100%;
}

.icons-container {
  @include flex-center;
  flex-direction: column;
  gap: 2rem;
  width: 4.8rem;
  height: 14rem;
  margin: 1.6rem 0 0 1.6rem;
  @include container-item;
}

.reset-button {
  width: 0;
  height: 0;
  background: transparent !important;
  border-color: transparent !important;
}

.reset-button:hover {
  background: transparent !important;
  border-color: transparent !important;
}

.submit-button-container {
  margin-top: 17rem;
  span {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
  }
}

.category-button-container {
  margin-top: 2.4rem;
}

.delete-button-container {
  position: absolute;
  top: 20px;
  right: 20px;
}