.tech-table {
  table{
    .ant-table-thead {
      margin-top: 0;
    }
    tr th {
      &:first-child {
        padding-left: 22px;
      }

      &:nth-child(3) {
        padding-left: 20px;
      }
    }

    .button-delete {
      line-height: 0;
      padding-top: 4px;
    }
  }
}