.start-wrapper {
  @include flex-center;
  min-height: 100vh;
  width: 100vw;
  background: $primaryBackgroud;
}
.start-container {
  width: 80rem;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: #e5e5e5;
  letter-spacing: 0.2px;
  background: #242424;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $darkerGrey;
  padding-bottom: 4rem;
  &__title {
    margin-bottom: 3.1rem !important;
    font-weight: $bold !important;
  }

  &__logo-container {
    @include flex-center;
    width: 100%;
    height: 11.2rem;
    background: #2a2a2a;
    border: 1px solid $darkerGrey;
    border-radius: 8px 8px 0 0;
  }

  &__instructions {
    padding: 6rem 7.3rem;
    border-bottom: 1px solid $darkerGrey;
  }

  &__instructions {
    .ant-typography:nth-child(2) {
      margin-bottom: 3.1rem !important;
    }
    .ant-typography:nth-child(3) {
      margin-bottom: 3.1rem !important;
      width: 57rem;
    }

    .ant-typography:nth-child(4) {
      margin-bottom: 3.1rem !important;
    }

    .ant-typography:nth-child(5) {
      margin-bottom: 3.1rem !important;
    }
  }
  &__contact {
    padding: 5.2rem 7.3rem;
    border-bottom: 1px solid $darkerGrey;
    .ant-typography:nth-child(1) {
      margin-bottom: 2.1rem !important;
      width: 57rem;
    }
  }
  &__info {
    padding: 4rem 7.3rem 0 7.3rem;
    .ant-typography:nth-child(1) {
      margin-bottom: 3.1rem !important;
    }

    &--column {
      max-width: 70%;
      font-size: 1.8rem;

      .ant-typography:nth-child(3) {
        margin-top: 1rem;
      }
    }
  }
  &__text {
    margin-left: 0.7rem;
  }

  &__text--green {
    color: $success;
    font-size: 1.8rem;
    margin-left: 1.2rem;
  }
  &__text--red {
    color: $paleRed;
  }
  &__icon {
    margin-left: 1.2rem;
  }
}
