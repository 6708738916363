.custom-timepicker {
  width: 100% !important;
  height: 4.3rem;
}
.custom-timepicker-container {
  width: 34rem !important;
}

// NEED TO WRAP EVERYTHING TO AVOID CONFLICTS WITH DIFFERENT TIMEPICKER IN APP
.custom-timepicker-dropdown {
  .ant-picker-panel {
    border: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  // THERE IS NO OTHER WAY TO SET UP DROPDOWN MENU WIDTH TO MATCH THE INPUT WIDTH, WIDTH: 100% DOESN'T WORK, MORE INFO:
  // https://github.com/ant-design/ant-design/issues/14419
  text-align: center;

  .ant-picker-panel-container {
    box-shadow: none !important;
    border: 1px solid $primary;
    border-radius: 0.7rem 0.7rem;
    width: 100% !important;
    background: $CharlestonGreen;
    font-size: 1.6rem !important;
    font-weight: 600;
    height: 16.5rem !important;
  }

  .ant-picker-ranges {
    display: none;
  }

  .ant-picker-footer-extra {
    display: none;
  }

  .ant-picker-footer {
    display: none;
  }

  .ant-picker-time-panel-column {
    width: 17rem;
    text-align: center !important;
  }

  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid $tundora;
    height: 100%;
  }

  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 0 !important;
  }

  .ant-picker-time-panel-column:not(:first-child) {
    padding-right: 0.6rem !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #3a3a3a;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: #5a5a5a !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    color: $platinium !important;
  }

  .ant-picker-time-panel-column {
    padding: 0 0.4rem;
    text-align: center;
  }

  .ant-picker-dropdown {
    text-align: center;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    padding: 0 !important;
  }

  .ant-picker-input > input:hover {
    border: none !important;
  }

  .ant-picker:hover {
    border-color: $tundora !important;
  }

  .ant-picker:hover,
  .ant-picker-focused .anticon-caret-down svg path {
    fill: $platinium !important;
  }
}

.ant-picker-input input {
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.6rem !important;
  font-weight: 300 !important;
  letter-spacing: 0.4px;
}

.left-column {
  width: 17.1rem;
  padding: 1rem 0;
  border-right: 1px solid $tundora;
}
.right-column {
  width: 16.5rem;
  padding: 1rem 0;
}

.custom-timepicker-content {
  border-radius: 0.7rem;
  border-color: $primary;
}

input:hover {
  border-color: $tundora !important;
}

.timepicker-text {
  letter-spacing: 1.2px;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $tundora;
}

.ant-picker-focused {
  border-color: $tundora !important;
  box-shadow: none;
  background: $secondary;
}

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
