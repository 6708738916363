.question-content {
  background-color: $veryDarkGrey;
  max-height: 26rem !important;
  overflow-y: scroll;
  border: 1px solid $secondary;
  border-radius: 0.8rem;
  padding: 1.7rem 1.9rem !important;
  width: 100% !important;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;

  &__checkbox--group {
    width: 100%;
  }

  &__title {
    @include fontStyles(1.4rem, $normal);
    color: $silverChalice;
  }

  &__question-instructions {
    @include fontStyles(1.6rem, $normal);
    line-height: 2.4rem;
  }

  &__instructions {
    @include fontStyles(1.6rem, $normal);
    padding-top: 1rem;
    line-height: 2.4rem;
    padding-bottom: 2rem !important;
  }
  &__preview {
    border: none !important;
    &:focus,
    &:hover {
      border: none !important;
    }
  }

  &__image {
    width: 32rem;
    border: 0.2rem solid $secondary;
    border-radius: 4px;
    object-fit: cover;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  div {
    border-radius: 8px !important;
    margin: 0 !important;
  }

  div code {
    font-size: 1.6rem !important;
  }

  div pre {
    border: 1px solid #454545;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em !important;
    font-weight: bold !important;
  }
}

.test-answers-container {
  display: flex;
  justify-content: center;
  width: 72rem;
}

.test-answer {
  width: 100%;
  max-height: 26rem;
  overflow-y: scroll;
  min-height: 10rem;
  padding: 1.9rem;
  border: 1px solid $secondary;
  border-radius: 0.8rem;
  background-color: $veryDarkGrey;
  margin-bottom: 2.2rem;
  font-size: 1.6rem;
  color: $gallery;
}

.test-answer.ant-radio-wrapper {
  white-space: normal;
}

.checked-answer {
  border: 1px solid #1dd78f !important;
}
