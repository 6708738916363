.dots-container {
  width: 6.4rem;
  height: 6.4rem;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.dot {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  &--green {
    background-color: $success;
    grid-area: 1/1;
  }
  &--blue {
    background-color: $primary;
    grid-area: 1/2;
    margin: 0 0 1.2rem 1.2rem;
  }
  &--red {
    background-color: $danger;
    grid-area: 2/2;
    margin: 0 0 0 auto;
  }
}
