.generated-test-content {
  width: 54rem;
  height: 47rem;
  padding: 4rem 4rem 5rem 4rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 3rem;
  }

  &-test {
    @include flex(center, space-between);
    &-info {
      @include flex(flex-start, center);
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      color: #616161;
      line-height: 1.7rem;
      &-text {
        margin-top: 1rem;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: 'SF Pro Text';
        color: #f0f0f0;
        line-height: 2.4rem;
        &-hour {
          margin-left: 0.5rem;
          font-family: 'SF Pro Text';
          color: #878787;
        }
        &-day {
          margin-top: 1rem;
          font-size: 1.6rem;
          font-weight: 400;
          font-family: 'SF Pro Text';
          color: #f0f0f0;
          line-height: 2.4rem;
        }
      }
      &.date {
        margin-right: 11.3rem;
        font-family: 'SF Pro Text';
      }
    }
  }
  //commented according to BB-826
  // &-show-result{
  //   font-size: 1.6rem;
  //   line-height: 2.4rem;
  //   color: #e5e5e5;
  //   margin: 3.5rem 0;
  // }
  &__link {
    margin-top: 10rem;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
    width: 100%;
    &-text {
      @include flex;
      height: 4rem;
      width: 100%;
      padding: 0 1rem;
      border-radius: 0.8rem;
      background-color: #2d2d2d;
      color: #f0f0f0;
      font-family: 'SF Pro Text', sans-serif;
      font-size: 1.6rem;
      margin: 1rem 0 6.5rem 0;
      text-overflow: ellipsis;
    }
  }
  &__buttons {
    width: 100%;
    @include flex(center, space-between);
    .ant-image {
      margin-right: 1rem;
    }
    .icon {
      z-index: -1;
    }
  }
}
