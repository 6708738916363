.modal-verification {
  top: 3rem;

  .ant-modal-content {
    width: 54rem;
    min-height: 100%;
    border: none;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    min-height: 40vh;
  }
}

.question-modal {
  min-height: 45rem;

  &__title {
    margin-top: 4.2rem;
    font-size: 1.8rem;
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  &__instruction {
    min-height: auto;
    word-wrap: break-word;
    margin: 1.3rem 0 2.6rem 0;
    line-height: 2.1rem;

    .preview {
      font-size: 1.8rem;
    }
  }

  &__picture {
    margin: 2.6rem 0;
  }

  &__answers {
    padding-top: 2.6rem;
    border-top: 1px solid #3d3d3d;
    font-weight: 400;
    font-size: 1.4rem;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1.3rem;
      padding: 2.4rem;
      background-color: #2d2d2d;
      border: 1px solid #454545;
      border-radius: 0.8rem;
      margin-top: 0.9rem;

      &__md-preview {
        pre {
          padding: 0;
          border-radius: 8px;
        }
      }
    }
  }

  &__close-button {
    margin: 3.6rem 0;
  }
}

.gray-text {
  color: #616161;
}
