.techtest-questions-list {
  overflow-y: scroll;
  width: 42rem;
  height: 100vh;
  background-color: $veryDarkGrey;
  scroll-behavior: smooth;
  .result-green, .result-orange, .result-red  {
    font-size: 1.4rem;
  }
}
.techtest-questions-list::-webkit-scrollbar {
  display: none;
}
.techtest-question-details {
  width: calc(100% - 42rem);
  height: 100vh;
}
.techtest-question-row {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 62.6rem;
  &--width50 {
    width: 50%;
  }
  &--width40 {
    width: 40%;
  }
}
.techtest-question-details-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  font-size: 1.8rem;
}
.techtest-arrow {
  position: absolute;
  top: 2.9rem;
  width: 1.6rem;
  height: 1.4rem;
  cursor: pointer;
}
.techtest-label {
  position: absolute;
  left: 2.9rem;
  top: 4rem;
  height: 1.7rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #bfbfbf;
}
.techtest-data {
  position: absolute;
  left: 2.9rem;
  top: 7rem;
  height: 2.1rem;
  max-width: 25rem;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: $primeWhite;
}
.techtest-candidate,
.techtest-data-candidate {
  left: 0;
}
.techtest-button--submit {
  position: absolute;
  top: 85vh;
  right: 0;
  color: $porcelain;
  span {
    font-family: 'SF Pro Text', sans-serif;
  }
}
.techtest-button--add {
  position: absolute;
  top: 85vh;
  right: 15rem;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.5rem;
  color: $primeWhite;
}
.ant-row .question-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-row .question-detail-title {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 500;
}
.question-detail-header .level {
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400;
}
.question-detail-header {
  .ant-badge-status-dot {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.question-detail-form {
  width: 100%;
}
.question-detail-form .form-item-custom {
  width: 100%;
  height: auto;
  .custom-input {
    margin-top: 1rem;
    box-shadow: none;
    border: .1rem solid rgba(242, 242, 242, 0.12);
    padding: .8rem 1.2rem;
    background-color: #3f3f3f;
    font-family: 'SF Pro Text', sans-serif;
    line-height: 2.4rem;
    color: $white;
  }
  .custom-input.question-detail-note {
    height: 12rem;
    resize: none;
  }
}
.question-detail-form .elements-label {
  margin-top: 2rem;
}
.techtest-category {
  justify-content: space-between;
  align-items: center;
  margin: 4rem 3rem 1.5rem 3rem;
  border: .1rem solid #f2f2f21f;
  border-radius: .8rem;
  padding: 1rem;
  background-color: #3f3f3f;
  .elements-label {
    width: 13rem;
    color: #e5e5e5a6;
  }
}
.techtest-questions-list .techtest-category-name {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
  &--float {
    float: right;
  }
}
.techtest-question {
  display:flex;
  justify-content: space-between;
  margin: 2rem 4rem;
  padding: 1rem 0;
  cursor: pointer;
  &--text {
    max-width: 92%;
  }
}
.techtest-questions-list-summary {
  margin: 3rem 2.5rem 2.5rem 4rem;
  .techtest-summary {
    border-top: .1rem solid $primary;
    width: 100%;
    padding: 2.5rem .5rem 0 .5rem;
    font-size: 1.8rem;
    font-weight: 400;
    color: $primeWhite;
    .result-red {
      color: $danger;
    }
    .result-orange {
      color: $warning;
    }
    .result-green {
      color: $success;
    }
    &--float {
      float: right;
    }
  }
}
.techtest-question-isHidden {
  display: none;
}

.techtest-question-details-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62.6rem;
  height: 41rem;
  border: .1rem solid #3D3D3D;
  box-shadow: 0 0 .8rem 0 rgba(0, 0, 0, 0.4);
  border-radius: .8rem;
  padding: 2rem;
  &-extra {
    height: auto;
  }
}

.techtest-question-rating {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: -2.3rem;
    .ant-checkbox {
      margin-right: 0;
    }
  }

.techtest-question-level {
  margin-bottom: -1rem;
  padding: 0;
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
    }
  }

}