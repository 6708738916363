.candidate-answer {
  &__container {
    margin: 4rem;
    max-height: 80vh;
  }

  &__title {
    font-size: 1.8rem;
  }

  &__row {
    width: 100%;
  }

  &__item {
    width: 100%;
    margin-bottom: 1.6rem;
  }

  &__closeButton {
    width: 100%;
    &--increasedHeight {
      height: 7.5rem;
    }
  }

  &__spinner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__answers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 2.2rem;
    background: $veryDarkGrey;
    border: 0.1rem solid $darkerGrey;
    border-radius: 0.8rem;

    &--correct {
      background-color: $success;
    }

    &--incorrect {
      background-color: $danger;
    }
  }
}

.details {
  .ant-table-tbody > tr > td:not(:first-child) {
    padding-left: 0;
  }

  .custom-table {
    &__col {
      height: auto;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 7rem;
    margin-top: 4rem;
    width: 100%;

    .left-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 1rem 2.5rem 2.2rem;
      width: 90rem;
      height: 100%;
      background-color: $veryDarkGrey;
      border: 1px solid rgba(242, 242, 242, 0.12);
      border-radius: 0.4rem;

      .test-insides {
        display: flex;
        flex-direction: row;
        width: 60%;

        .header-text {
          display: flex;
          align-items: center;
          width: auto;

          &--subjects {
            font-family: 'SF Pro Display', sans-serif;
            font-size: 1.8rem;
            font-weight: 400;
            width: 65% !important;
            text-overflow: ellipsis;
            overflow-x: scroll;
            white-space: nowrap;
            margin-bottom: -0.8rem;

            &::-webkit-scrollbar {
              height: 5px;
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              margin-bottom: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(242, 242, 242, 0.12);
              border: 1px solid transparent;
              border-radius: 4px;
            }
          }
        }

        &__status {
          display: flex;
          color: #ffa39e;
          justify-content: center;
          border: 1px solid #ffa39e;
          border-radius: 2px;
          font-size: 12px;
          font-family: 'SF Pro Text', sans-serif;
          font-weight: 400;
          padding: 0 8px;
        }
      }

      .test-time-taken {
        display: flex;
        flex-direction: row;

        .header-text {
          white-space: nowrap;
        }

        &__icon {
          color: #0acf83;
        }

        &__time {
          color: #0acf83;
        }
      }
    }

    .right-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 37.6rem;
      padding: 0 2.2rem ;
      font-size: 1.8rem;
      height: 100%;
      border-radius: 0.4rem;

      &--success {
        background: #273832;
        border: 1px solid #0acf83;

        .succes {
          font-size: 22px;
          color: #15e293;
        }
      }

      &--warning {
        background-color: #383727;
        border: 1px solid #eabd4d;

        .warning {
          color: #f1c75c;
        }
      }

      &--danger {
        background-color: #382727;
        border: 1px solid #e06c76;

        .danger {
          color: #f1838c;
        }
      }
    }
  }

  &__summary {
    margin: 4rem 0;
    height: 5.3rem;
    display: flex;
    justify-content: space-between;

    .left-box {
      display: flex;
      align-items: center;
      width: 80rem;
      height: 100%;
      background-color: transparent;
      padding-left: 2.2rem;
      border-radius: 0.4rem;

      .box-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 18.1rem;
        height: inherit;

        &__title {
          color: #707070;
          font-size: 1.6rem;
        }

        &__text {
          color: #f0f0f0;
          font-size: 1.8rem;

          .time {
            color: #707070;
            font-size: 1.8rem;
            margin-left: 1rem;
          }

          &--data {
            width: 22.5rem;
          }
        }
      }
    }

    .right-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 37.6rem;
      padding: 0 2.2rem;
      height: 100%;
      border-radius: 0.4rem;

      &__result {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: inherit;

        &--title {
          color: #707070;
          font-size: 1.6rem;
        }

        .result-bottom {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          &--description {
            color: #f0f0f0;
            font-size: 1.8rem;
          }

          &--result {
            display: flex;
            align-items: center;
            font-size: 1.8rem;

            .percent {
              margin-left: 1rem;

              &--danger {
                color: #ffa39e;
              }

              &--warning {
                color: #eabd4d;
              }

              &--success {
                color: #15e293;
              }
            }
          }
        }
      }
    }
  }
}

.table {
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 7rem;
    width: 100%;
    background: #2d2d2d;
    border: 1px solid rgba(242, 242, 242, 0.12);
    border-radius: 4px;

    &__title {
      padding: 0;
      font-size: 16px;
    }
  }

  .first {
    width: 44%;
    text-align: left;
    font-size: 1.8rem;

    span {
      margin-left: 2rem;
    }
  }

  .second {
    width: 20%;
    text-align: left;
  }

  .third {
    width: 10%;
    text-overflow: ellipsis;

    span {
      width: 7rem;
    }
  }

  .fourth {
    width: 10%;
    text-align: center;
    text-overflow: ellipsis;
    justify-content: center;
    overflow: visible;

    span {
      width: 5rem;
    }
  }

  .fifth {
    width: 10%;
    text-align: center;
    text-overflow: ellipsis;
    justify-content: center;
    overflow: visible;

    span {
      width: 5rem;
    }
  }

  .sixth {
    width: 6%;
    justify-content: flex-end;
    text-align: right !important;

    span {
      width: 100%;
      text-align: right !important;
      margin-right: 2rem;
    }
  }
  .withoutQuestionTime {
    width: 54%;
    justify-content: flex-start;
    text-align: left !important;

    span {
      width: 100%;
      text-align: left !important;
      margin-left: 2rem;
    }
  }

  .percentage {
    padding: 0;
  }

  .ant-table {
    margin-top: -3.5rem;
    margin-bottom: 2rem;

    .ant-table-title {
      padding: 0 !important;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    vertical-align: middle;
    padding-left: 2.2rem;

    &:first-child {
      padding-right: 0;
    }
  }

  .ant-table tfoot > tr > td:first-child {
    padding-right: 0;
  }
}

.correct {
  background-color: lighten($success, 15);
  color: white;
}

.incorrect {
  background-color: lighten($danger, 5);
  color: white;
}

.stateless {
  background-color: lighten($dark, 20);
}

.header {
  @include flex(center, center);
  margin: 0 auto 2rem;
}

.details-title {
  @include fontStyles(1.7rem);
  margin: 2rem 0;

  &__info {
    @include fontStyles(2rem);
    margin: 2rem 0;
  }
}

.details__detailed-answer {
  width: 60%;
  margin: auto;
}

.row-background {
  background-color: $dark;
}

.col-margin {
  padding: 0 5.2rem !important;
}

.header-text {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 1.8rem;
  line-height: 2.15rem;
  font-weight: 400;
  margin-right: 1rem !important;
}

.results-divider {
  margin: 0.5rem 0;
}

.btn--generate {
  margin: 2rem 0;
}

.test-results-row {
  padding: 0.5rem;
  border-radius: 2rem;
  transition: background 0.2s ease;

  &:hover {
    background: $secondary;
  }
}

.answers-table {
  overflow-x: scroll;

  .ant-table-thead > tr > th {
    background: lighten($dark, 10) !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 1rem 0 1rem;
  }

  & .ant-table .ant-table-tbody > .incorrect > td {
    background-color: lighten($danger, 10);
  }

  & .ant-table .ant-table-tbody > .correct > td {
    background-color: lighten($success, 10);
  }

  & .ant-table .ant-table-tbody > .incorrect:hover > td {
    background-color: lighten($danger, 10);
  }

  & .ant-table .ant-table-tbody > .correct:hover > td {
    background-color: lighten($success, 10);
  }
}

.test-result {
  font-size: 1.8rem;
  line-height: 2.15rem;
  font-weight: 400;
  width: 37.6rem !important;
  height: 7rem;
  padding: 2.4rem 2.2rem !important;
  justify-content: space-between !important;

  &--danger {
    background-color: lighten($color: $danger, $amount: 5%);
    border: 1px solid $danger;
    border-radius: 0.4rem;
    opacity: 0.9;

    .result {
      color: $danger;
    }
  }

  &--warning {
    background-color: lighten($color: $warning, $amount: 5%);
    border: 1px solid $warning;
    opacity: 0.9;

    .result {
      color: $warning;
    }
  }

  &--success {
    background-color: lighten($color: $success, $amount: 5%);
    border: 1px solid $success;
    opacity: 0.9;

    .result {
      color: $success;
    }
  }

  .result {
    margin: 0 0 0 auto;
  }
}

.danger {
  color: darken($danger, 10%);
}

.warning {
  color: darken($warning, 10%);
}

.success {
  color: darken($success, 10%);
}

.answer-details__modal {
  .answer-details__redirect-button {
    position: absolute;
    bottom: 0;
    margin: 1rem 0;
  }

  .ant-modal-header {
    border-radius: 2rem 2rem 0 0;
    border: none;
  }

  .ant-modal-body {
    margin: 2rem 0;
    max-height: 50vh;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    border: none;
  }
}

.answer-details__modal-open {
  width: 53.9rem;
  height: 60rem;

  .closed-q--icon {
    margin-left: 0;
  }

  .ant-modal-content {
    border-radius: 0.8rem;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 4.1rem 2.6rem 4.1rem 3.9rem;

    .header-result {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .closed-q--icon {
        color: black;
      }
    }

    .title-result {
      margin-left: 1.3rem;
      font-size: 1.8rem;

      &__text {
        margin-right: 4.5rem;
      }

      .eye-icon {
        .anticon {
          color: #616161;
          font-size: 1.6rem;
          width: 2.4rem;
          height: 2.4rem;

          &:hover {
            color: #c4c4c4;
          }
        }
      }
    }

    .title-author {
      margin-top: 4.4rem;
      font-size: 1.6rem;
      color: #707070;
    }

    .author {
      margin-top: 1.3rem;
      font-size: 1.8rem;
    }

    .title-note {
      font-size: 16px;
      margin-top: 3.7rem;
      color: #707070;

      &.title-note-toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1.3rem;
        cursor: pointer;
      }

      &__arrow {
        transform: translateY(10%);
        font-size: 1.25rem;
      }
    }

    .note {
      margin: 1.3rem 0;
      font-size: 1.8rem;
      line-height: 21.48px;
      font-weight: 400;
    }

    .close-button {
      height: 40px;
      font-size: 16px;
      border-radius: 5px;
    }

    .close-modal {
      margin-top: 4.9rem;

      .button {
        border-radius: 0.8rem;
        background: #3d6ff8;
        width: 8.5rem;
        height: 4rem;
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.marg-left-1rem {
  margin-left: 1rem;
}
