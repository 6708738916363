.open-questions-to-verify {
  height: 100vh;
  overflow: auto;
  &-container {
    background-color: $dark;
    &__title {
      @include fontStyles(2.4rem, $bold);
      padding: 2rem 3rem;
      color: white;
    }
    &__table {
      .table {
        margin: 0 2rem;
        thead[class*='ant-table-thead'] th {
          background-color: $dark !important;
          pointer-events: none;
        }
        .ant-table-column-sorters {
          pointer-events: initial;
          padding: 0 0 2rem 0;
        }
      }
    }
  }
}
