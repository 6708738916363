.btn-toggler {
  padding: 0.1rem 0.1rem;
  display: flex;
  justify-content: center;
  font-size: 1.44rem;
  align-items: center;
  height: 100%;
  width: 18%;
  border-radius: 1rem;
  border: 0.2rem solid #3d6ff8;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    color: #177ddc;
  }
  &--selected {
    background-color: #3d6ff8;
    &:hover {
      color: inherit;
      background-color: #165996;
    }
  }
}
