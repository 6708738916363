.duplicate-template-modal-container {
  width: 560px !important;

  &__heading {
    margin-bottom: 5.3rem;
  }
  .duplicate-template-modal-form {
    &__item {
      display: flex;
      flex-direction: column;
    }

    &__item:first-child {
      margin-bottom: 2.3rem;
    }

    &__label {
      text-align: left;
      color: $light;
      opacity: 0.6;
      font-size: 1.4rem;
    }

    &__input {
      width: 100%;
      padding: 0.8rem 1.2rem;
      background: $CharlestonGreen;
      font-size: 1.6rem;
      font-weight: 300;
      letter-spacing: 0.2px;
      color: $light;
      border-radius: $main-radius;
    }

    &__button-container {
      margin-top: 21.3rem !important;
    }
  }

  .ant-form-horizontal .ant-form-item-label {
    text-align: left;
  }
}
