.open-question-verification {
  height: 100vh;
  overflow: hidden;
  padding: 0 3.8rem;
  &-container {
    padding: 0 3rem 2.8rem 3rem;
    position: relative;
    background-color: $dark;
    .react-codemirror2 {
      width: 462px;
    }
    &__title {
      @include fontStyles(2.4rem, $bold);
      padding: 2rem 0;
      color: $white;
    }
    &__header {
      width: 100%;
      @include fontStyles(1.8rem, $bold);
      padding: 1rem 0;
      color: $white;
    }
    &__code-mirror {
      background: #222222;
      width: 462px !important;
      .CodeMirror {
        height: auto;
      }
      @include fontStyles(1.4rem);
      padding-top: 0.4rem;
    }
    &__buttons-container {
      padding: 2rem 0;
      .correct-button {
        margin-left: 1rem;
      }
    }
    &__note-content {
      width: 100%;
      display: flex;
      .note-button {
        align-self: center;
      }
    }
  }
}
.modal-verification {
  top: 3rem;
  .ant-modal-content {
    width: 54rem;
    min-height: 80vh;
    border: none;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    width: 54rem;
    min-height: 80vh;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  width: 54rem;
  min-height: 80vh;
  background-color: #242424;
  border-radius: 0.8rem;
  padding: 4rem 0 5.3rem 0;

  .ant-upload-list-item-card-actions {
    display: none !important;
  }
  &__modal-title {
    font-size: 1.8rem;
    width: 100%;
  }
  &__title {
    font-size: 1.4rem;
    color: #bfbfbf;
    width: 100%;
    padding-top: 3.9rem;
  }

  &__title-value {
    font-size: 1.8rem;
    margin-top: 1.3rem;
    width: 100%;
  }
  &__question-header {
    margin-top: 4rem;
    width: 100%;
    font-size: 1.4rem;
    color: #616161;
  }

  &__question-value {
    margin-top: 1.3rem;
    margin-bottom: 0.9rem;
    color: #f0f0f0;
    p {
      font-size: 1.8rem !important;
    }
  }
  &__candidate-header {
    color: #616161;
    font-size: 1.4rem;
  }
  &__ &__note-header {
    margin-bottom: 0.4rem;
    color: #616161;
    font-size: 1.4rem;
  }
  &__content-answer {
    width: 46.2rem !important;
    font-size: 1.6rem;
    border-radius: 0.8rem;
    background-color: $veryDarkGrey;
    color: white !important;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 5rem;
      background-color: $veryDarkGrey;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(52 52 52);
      border-bottom-right-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
    }
  }
  &__answer-value {
    margin-top: 0.9rem;
  }
  &__note-content {
    width: 46.2rem !important;
    height: 9.2rem !important;
    font-size: 1.6rem;
    font-family: 'SF Pro Text', sans-serif;
    border-radius: 0.8rem;
    background-color: $veryDarkGrey;
    color: white !important;
  }
  &__buttons {
    margin-top: 4.6rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    &-mark {
      display: flex;
      width: fit-content;
      button {
        margin: 0 1rem;
      }
    }
  }
  .box-picture {
    width: 7.2rem;
    height: 4rem;
    background: #3b3b3b;
    border-radius: 0.8rem;
    .picture {
      margin-top: 0.7rem;
      margin-left: 0.7rem;
    }
  }
}
.pictureView {
  background: #3b3b3b;
  border: 1px solid #454545;
  box-sizing: border-box;
  border-radius: 4px;
}
.pictureViewRadius {
  border-radius: 4px;
  object-fit: scale-down;
}

.upload-row {
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;

  &--no-margins {
    margin: 0;
  }

  .modalUpload {
    display: flex;
    align-items: center;
    background: #2d2d2d;
    width: 328px;
    height: 40px;
    border-radius: 0.8rem;
    border: 1px solid #454545;
    cursor: pointer;
    &:hover {
      border-color: #3d6ff8;
    }
    .upload-name {
      display: flex;
      align-items: center;
      padding: 0;
      font-weight: $normal;
      .file-icon {
        margin: auto 1rem;
        width: 1.9rem;
        height: 1.9rem;
      }
    }
    .ant-upload-list {
      .ant-upload-list-item-info {
        background-color: #2d2d2d;
        width: 32.8rem !important;
        height: 4rem !important;
        padding: 1.1rem 1.6rem 1.1rem 0;
        border: 1px solid #454545 !important;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        font-weight: $normal;

        a {
          color: #ffffff !important;
        }

        &:hover {
          border: 1px solid #6089fb !important;
          a {
            color: #ffffff !important;
          }
        }
        .ant-upload-text-icon {
          display: none;
        }
      }
    }
  }
  .uploadButton {
    &--no-margins {
      margin: 0;
    }

    &:hover {
      .arrow-down {
        path {
          fill: white !important;
        }
      }
    }
    .arrow-down {
      margin-left: 1rem;
    }

    width: 11rem;
    display: flex;
    height: 4rem !important;
    align-items: center;
    margin-left: 2.4rem;
    background-color: #2d2d2d;
    padding: 1rem 1.6rem 1rem 1.6rem;
    border: 1px solid #454545 !important;
    border-radius: 0.8rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #ffffff;
    font-weight: $normal;
    &:hover {
      background: #3d6ff8;
      border: 1px solid #6089fb;
      color: #ffffff;
    }
  }
}

.candidate-section {
  padding: 0 3.8rem 3.6rem 3.8rem;
  border-bottom: 1px solid #3d3d3d;
}

.answer-section {
  padding: 3.4rem 3.8rem;
  border-bottom: 1px solid #3d3d3d;
  .answer-value {
    margin-top: 0.9rem;
  }
}

.note-section {
  padding: 3.4rem 3.8rem 0 3.8rem;
  .note-title {
    margin-bottom: 0.9rem;
    div {
      color: #616161;
    }
  }
}
.answer-code code {
  font-size: 1.6rem !important;
}
.answer-code {
  div {
    border-radius: 8px !important;
    height: 25.5rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 5rem;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(30, 30, 30);
      border-bottom-right-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
    }
  }
}
