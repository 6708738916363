.modal-wrap {
  .ant-modal-content {
    border-radius: 0.8rem;
    background-color: transparent;
    border: transparent;
  }
}

.fail-add-new-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36rem;
  height: 35rem;
  background-color: #242424;
  border: 1px solid #3d3d3d;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 0 4rem;
  &__icon {
    margin-top: 4.4rem;
  }
  &__paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21.5px;
    text-align: center;
    color: #f0f0f0;
    margin-top: 9.6rem;
    &--second {
      margin-top: 0;
    }
  }
  &__button {
    margin: 5rem 0 0 0;
  }
  &__container {
    width: 100%;
    @include flex(center, space-between);
  }
}
