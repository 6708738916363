.candidates_container-row {
  height: 100vh;
  width: 100%;
  font-family: "SF-Pro Display", sans-serif;
  background-color: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  .candidates_content-col {
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 0 2rem;
    align-items: center;
    &__box {
      width: 100rem;
      height: 50rem;
      display: flex;
      flex-direction: column;
      background-color: $dark;
      .box {
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: $veryDarkGrey;
        border-radius: 1.2rem;
        height: 240px;
        &--second {
          margin-top: 2rem;
        }
        .box-left {
          width: 56.8rem;
          height: 100%;
          border-right: 2px solid $dark;
          display: flex;
          .box-icon {
            width: 11.5rem;
            height: 11rem;
            margin-top: 6.6rem;
            margin-left: 6.4rem;
          }
          .box-count {
            margin-top: 9.7rem;
            margin-left: 5.6rem;
            font-size: 3.6rem;
          }
        }
        .box-right {
          width: 43.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &__elements {
            width: 36rem;
            display: flex;
            flex-direction: column;
            height: 21rem;
            .element {
              border: 1px solid transparent;
              &:hover {
                .box-count-top {
                  background: #3d6ff8;
                }
                .box-count-down {
                  background: #0f9863;
                }
              }
              display: flex;
              padding: 2.55rem 2rem 2.55rem 2rem;
              width: 100%;
              align-items: center;
              font-size: 1.6rem;
              justify-content: space-between;
              height: 7rem;
              background: $veryDarkGrey;
              border-radius: 0.8rem;
              .box-count {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 8.3rem;
                height: 3.6rem;
                border: 1px solid #454545;
                border-radius: 0.6rem;
              }
              &:hover {
                background: #3f3f3f;
                border: 1px solid #f2f2f21f;
              }
            }
          }
        }
      }
    }
  }
}
