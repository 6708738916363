.element_remove {
  cursor: pointer;
}

.container_modal {
  border-radius: 3rem;
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}

.average_score {
  color: #0acf83;
}

.ant-table-row {
  height: 8.7rem;
}

.edit-button {
  border: 0.2rem solid #3d6ff8;
  padding: 0 2rem;
  border-radius: 0.8rem;
}

.delete-button {
  border: 0.2rem solid #aa3838;
  padding: 0 2.8rem;
  border-radius: 0.8rem;
  margin-right: 1rem;
}

.question-type {
  color: $mercury;
  font-family: 'SF Pro Text';
  font-size: 1.2rem;
  border: solid 1px #3e3e3e;
  border-radius: 1.2rem;
  height: 2.2rem;
  background-color: $veryDarkGrey;
  padding: 0.1rem 0.8rem;
  width: fit-content;
}

.question-time {
  @include flex;
  font-family: 'SF Pro Text';
  font-size: 1.4rem;
  color: $mercury;
  transform: translateY(1rem);

  &__icon {
    font-size: 2.4rem;
    color: #c4c4c4;
    margin-right: 0.5rem;
  }
}

.level {
  padding: 0.5rem 0;
  @include flex;
  text-align: left;
  font-family: 'SF Pro Text' !important;
  font-size: 1.4rem;
  span {
    font-family: 'SF Pro Text' !important;
  }
  &-dot {
    margin-right: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    &.step.one {
      background-color: $java;
    }

    &.junior {
      background-color: $mountainMeadow;
    }

    &.professional {
      background-color: $warning;
    }

    &.senior {
      background-color: $monaLisa;
    }
  }

  &.step.one {
    @include buttonColor($java);
  }

  &.junior {
    @include buttonColor($mountainMeadow);
  }

  &.professional {
    @include buttonColor($warning);
  }

  &.senior {
    @include buttonColor($monaLisa);
  }
}

.add-question-button {
  vertical-align: middle;
  & svg path {
    transition: 0.3s;
  }
  &.selected svg path {
    fill: $mountainMeadow;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover svg path {
    fill: $mountainMeadow;
  }
}
