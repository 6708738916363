.add-question {
  display: flex;
  justify-content: center;
  background: $dark;
  min-height: 100vh !important;
  &__content-container {
    padding: 4.2rem 0 13.2rem 0;
    width: 72rem;
  }

  &__form-container {
    margin-top: 3.8rem;
  }

  &__title {
    font-weight: 400 !important;
    font-size: 2rem !important;
  }
}

.add-question-form {
  width: 100%;
  &__label {
    color: $light;
    opacity: 0.6;
    font-size: 1.4rem;
  }

  &__input {
    padding: 0.8rem 1.2rem;
    background: $CharlestonGreen;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 0.2px;
    color: $light;
    border-radius: $main-radius;
  }

  &__item {
    &--textarea {
      margin-top: 2.2rem;
    }

    &--bbcode {
      margin-top: 2.4rem;
      .ant-form-item-explain-error {
        margin-top: 3rem;
      }
    }
  }

  &__textarea {
    min-height: 6.2rem !important;
  }

  input::placeholder {
    color: $platinium !important;
    opacity: 0.6 !important;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0.9rem !important;
}

.add-question .ant-input {
  padding: 0.8rem 1.2rem;
  font-size: 1.6rem;
  font-weight: 300 !important;
  letter-spacing: 0.2px;
  background: $CharlestonGreen;
  border-radius: $main-radius;
}

.add-question .ant-input:hover {
  border-color: rgba(242, 242, 242, 0.12);
  box-shadow: none !important;
}

.add-question .ant-input:focus {
  border-color: rgba(242, 242, 242, 0.12);
  box-shadow: none !important;
}

.answer {
  margin-bottom: 2.4rem;
  &__delete-button {
    @include flex();
    width: 6.1rem;
    height: 2.4rem;
    padding: 1px 8px;
    font-size: 1.2rem;
    color: $paleRed;
    border: 1px solid $paleRed;
    border-radius: 0.2rem;
    transition: 0.3s;
  }

  &__delete-button:hover {
    color: $paleRed;
    background: $masala;
    border: 1px solid $paleRed;
  }

  &__delete-button:disabled .anticon svg path {
    fill: $secondary !important;
  }

  &__checkbox {
    margin-right: 1.6rem;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    color: $porcelain;
  }
}

.add-answer-button {
  width: 100% !important;
  height: 8rem;
  background: $veryDarkGrey;
  border: 1px dashed $tundora;
  border-radius: 0.8rem;
  color: $gallery;
  font-size: 1.5rem;
  font-weight: 400;
  transition: 0.3s;
}

.add-answer-button:hover {
  background: $mineShaft;
  border: 1px dashed $tundora;
  color: $gallery;
}

.ant-btn:focus {
  background: $veryDarkGrey;
  border-color: $tundora;
  border-radius: 8px;
  color: $gallery;
}

.custom-code-mirror {
  border-radius: 0 8px !important;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-family: 'SF Pro Display', sans-serif;
}

.CodeMirror {
  border-radius: 0.8rem;
  padding: 1.2rem 2.4rem;
}

.file-block-container {
  @include flex-center;
  flex-direction: column;
  text-align: center;
  height: 17.7rem;
  color: $gallery;
  background: $veryDarkGrey;
  border: 1px dashed $tundora;
  border-radius: 0.8rem;
}

.file-label {
  &__text {
    color: $brightGrey;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
    max-width: 300px;
  }

  &__title {
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    color: $gallery;
  }

  &__icon {
    margin-right: 1.2rem;
  }
}

.add-image-button {
  @include flex-center;
  flex-direction: row;
  width: 22.7rem;
  height: 4rem;
  padding: 1.1rem 1.2rem;
  background: $veryDarkGrey;
  border: 1px solid $tundora;
}

.answer-container,
.instruction-container {
  margin-bottom: 6rem;
}

.radio-edit-disable {
  .ant-radio-disabled .ant-radio-inner::after {
    display: none;
  }
}
