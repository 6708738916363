.sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.content-children {
  overflow-x: hidden;
  width: calc(100vw - 6rem);
  margin: 0 0 0 6rem;
  min-height: 100vh;
  background-color: $dark;
}

.container-internal-quizzeslist__row .container-internal-quizzeslist__col {
  min-height: 100vh;
}

.container-row__col {
  height: 100%;
}

.app-message {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100vw;
  padding: 0.3rem 1rem 0.3rem 0.3rem;
  font-weight: 300;
  font-family: 'SF Pro Text', sans-serif;
  background: $primary;
  color: $porcelain;
}
