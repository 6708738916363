.editor {
  border-radius: 0.8rem 0.8rem 0 0;
  border: none !important;
}

.js-plugin-container {
  display: flex !important;
  background: $CharlestonGreen !important;
  border: 1px solid $tundora !important;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1rem 1.6rem !important;
}

.js-editor-menu {
  display: flex !important;
}

.s-editor-btn {
  margin-right: 1.4rem;
}

.icon-bg {
  width: 2.2rem;
  height: 2.2rem;
}

.is-selected {
  border: 1px solid $dodgerBlue !important;
  border-radius: 0.3rem;
  background: $charade !important;
}

.is-selected span {
  color: $dodgerBlue;
}

.js-editor {
  height: 15.3rem;
  padding: 1rem;
  border: 0.2rem solid $dodgerBlue !important;
  border-radius: 0 0 0.8rem 0.8rem;
}

.js-editor:focus {
  outline-color: $dodgerBlue !important;
  border-color: $dodgerBlue !important;
}

textarea:focus {
  outline-color: $dodgerBlue !important;
  border-color: $dodgerBlue !important;
}

.js-editor .ProseMirror {
  outline-color: $dodgerBlue !important;
  border-color: $dodgerBlue !important;
}

.ProseMirror:focus {
  outline: none !important;
  border: 0.2rem solid $dodgerBlue !important;
}

.markdown {
  width: 100%;
  height: 19rem;
  background: $CharlestonGreen !important;
  margin-top: 2.4rem;
  border-radius: 0.8rem;
  border: 1px solid $tundora;
  position: relative;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.add-image-button {
  @include flex-center;
  flex-direction: row;
  width: 20.1rem;
  height: 4rem;
  padding: 1.1rem 1.2rem;
  margin-top: 2.4rem;
  background: $CharlestonGreen;
  border: 1px solid $tundora;
  border-radius: 0.8rem;
  color: $gallery;
  font-weight: 500 !important;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 1.5rem;
  transition: 0.3s;
}

.add-image-button:hover {
  background: $mineShaft;
  color: $gallery;
  border: 1px solid $tundora;
}

.w-md-editor-content {
  background-color: $mineShaft !important;
  height: 20rem;
  border-radius: 0 0 0.8rem 0.8rem;
}

.w-md-editor-aree.w-md-editor-input {
  border: 0.2rem solid $dodgerBlue !important;
  border-radius: 0 0 0.8rem 0.8rem !important;
}

.wmde-markdown.wmde-markdown-color.w-md-editor-preview {
  border-top: none !important;
}

.w-md-editor-aree.w-md-editor-input {
  padding: 2.4rem;
  background-color: $mineShaft !important;
}

.w-md-editor-aree.w-md-editor-input {
  background-color: #242424 !important;
}

div.w-md-editor-aree.w-md-editor-input > div {
  background-color: #242424 !important;
}

.w-md-editor-toolbar {
  height: 4.8rem !important;
  border-radius: 0.8rem 0.8rem 0 0 !important;
  background: $CharlestonGreen !important;
  border-right: 1px solid $tundora !important;
  border-left: 1px solid $tundora !important;
  border-top: 1px solid $tundora !important;
  border-bottom: none;
  padding-left: 1.6rem;
}

.w-md-editor {
  background-color: transparent;
  border-radius: 0 !important;
  font-size: 1.6rem;
  color: $gallery !important;
}

.w-md-editor-text {
  font-size: 1.6rem !important;
}

.w-md-editor-aree {
  border-radius: 0 !important;
}

.w-md-editor-toolbar ul li {
  @include flex-center;
  width: 2.8rem;
  height: 2.8rem;
  color: $alto;
  margin-right: 14px;
}

.w-md-editor-toolbar ul li svg {
  width: 1.8rem;
  height: 1.8rem;
}

.w-md-editor-toolbar ul {
  display: flex;
  align-items: center;
}

.w-md-editor-toolbar li > button {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  height: 2.8rem;
}

.w-md-editor-toolbar li button svg path {
  fill: $alto;
}

.w-md-editor-toolbar li > button:hover,
.w-md-editor-toolbar li > button:focus {
  background-color: $charade !important;
  border: 1px solid $dodgerBlue !important;
  border-radius: 0.3rem;
}

div.wmde-markdown.wmde-markdown-color.w-md-editor-preview {
  background-color: $CharlestonGreen !important;
  padding: 2.4rem;
  background: $CharlestonGreen;
  border: 1px solid $tundora !important;
  border-radius: 0 0 8px 8px;
}

.wmde-markdown-color code[class*='language-'] {
  color: rgba(255, 255, 255, 0.4);
}

.w-md-editor-text-pre {
  color: $alto !important;
}

.w-md-editor-text-pre .bold {
  color: $alto !important;
}

.layout .row .ant-row .content {
  background: transparent !important;
}

.custom-mde-editor {
  width: 100% !important;
  height: 300px !important;
}

.token .code-block {
  color: white !important;
}

.w-md-editor-preview {
  box-shadow: none !important;
}

.w-md-editor-preview pre div {
  margin: 0 !important;
  font-size: 1.6rem;
}

.w-md-editor-preview pre div code {
  font-size: 1.5rem !important;
}

.w-md-editor-toolbar li > button {
  color: $silverChalice !important;
}

li.active {
  background: $charade;
  border: 1px solid $dodgerBlue;
  border-radius: 0.3rem;
}

li.active button span svg path {
  fill: $dodgerBlue;
}

li.active button span svg ellipse {
  fill: $dodgerBlue;
}

.w-md-editor-text-pre .title,
.w-md-editor-text-pre .bold {
  color: rgba(255, 255, 255, 0.4) !important;
}

.wmde-markdown pre {
  background-color: transparent !important;
}

.syntax-select {
  height: 2.8rem !important;
  width: 17rem !important;
  margin-bottom: 0.4rem;
}

.syntax-select .ant-select {
  width: 17rem !important;
  position: absolute !important;
}

.ant-form-item-control-input-content{
.ant-select.ant-select-single.ant-select-show-arrow {
  border-radius: 1rem !important;
.ant-select-selector {
  span {
    font-size: 1.6rem !important;
    font-family: 'SF Pro Text', sans-serif !important;
    font-weight: 400;
  }
}
}
}

  .ant-select.ant-select-single.ant-select-show-arrow {
    border-radius: 1rem !important;
  .ant-select-selector {
    span {
      font-size: 1.4rem !important;
      font-family: 'SF Pro Text', sans-serif !important;
      font-weight: 400 !important;
    }
    }
  }

.label-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.label-container .ant-form-item {
  margin-bottom: 0;
}

.syntax-select {
  width: 160px !important;
  height: 32px !important;
  margin-left: 50px;
}
