.table-striped-rows tr:nth-child(2n) td {
  background-color: $dark;
}
.table-stripped-rows {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 2.3rem 1.6rem;
    margin: auto !important;
  }
}

.container-row {
  &__col {
    background-color: $dark;
    position: relative;
    .table {
      margin: 0 0 0 2rem;
    }
    .header {
      margin-top: 2%;
      height: 8%;
      &__title-col {
        .title-container {
          @include flex();
          &__text {
            @include fontStyles(2.4rem, $bold);
            padding-left: 2rem;
            color: white;
          }
        }
      }
    }
    .questions-container {
      @include flex($justify: flex-end);
      gap: 0.5rem;
      flex-wrap: wrap;
      &__col {
        .input-search {
          background: $secondary;
          .ant-input-search-button {
            padding: 0 0.8rem;
            background-color: $primary;
            border: none;
            outline: none;
          }
        }
        .dropdown-filter {
          width: 15rem;
          background: $secondary;
          .ant-select-selection-placeholder {
            color: inherit;
            font-family: "SF Pro Text", sans-serif;
          }
          .ant-select-arrow {
            color: inherit;
          }
          .dropdown-button {
            width: 100%;
          }
        }
        .table-row {
          &__col {
            min-height: 90%;
          }
        }
      }
    }
  }
}
.ant-table-thead > tr > .ant-table-cell {
  font-weight: 700;
}
.ant-table-pagination-right {
  justify-content: center;
}
.ant-table-pagination.ant-pagination {
  margin: 4rem 0;
}
