.form-select {
  height: 4.3rem;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 1.6rem !important;
  font-weight: 300 !important;
  background: $CharlestonGreen;
  border-radius: $main-radius;
  &__tag {
    margin-left: 0.4rem;
  }
}

.form-select .ant-select-selection-placeholder {
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 400 !important;
  letter-spacing: 0.4px;
  color: $platinium;
  opacity: 0.65 !important;
}

.form-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
}

.form-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}

.form-select .ant-select-selector {
  display: flex;
  align-items: center;
  height: 100% !important;
}

.form-select .ant-select-selector .ant-checkbox {
  display: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: $hidden !important;
}

.add-question-form__item .ant-select-focused {
  outline: none !important;
  border: none !important;
  background: $secondary;
}

.add-question-form__item .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  top: 5px;
}

.add-question-form__item
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

.add-question-form__item
  .ant-select-item
  .ant-select-item-option
  .ant-select-item-option-grouped
  .ant-select-item-option-active {
  padding: 0 !important;
}

.add-question-form__item .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid $tundora;
}

.add-question-form__item
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: 1px solid $porcelain;
}

.add-question-form__item
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #4d4d4d;
}

.custom-checkbox {
  font-size: 1.6rem !important;
  font-family: 'SF Pro Text', sans-serif;
  font-weight: 300 !important;
  letter-spacing: 0.4px;
}
