.finish-container {
  background: #242424;
  border: 1px solid #3d3d3d;
  box-sizing: border-box;
  height: 100vh;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  &__col {
    font-size: 2.4rem;
    background-color: #242424;
    border: 1px solid $darkerGrey;
    border-radius: 0.8rem;
    &--no-result {
      width: 36rem;
    }
  }
}
.finish-background {
  background-color: black;
}
.header-logo {
  margin-top: 4.2rem;
}
.finish-text {
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.15rem;
  margin-top: 4.4rem;
  &--lower {
    width: 80%;
    margin-top: 1rem;
    margin-bottom: 3.5rem !important;
    white-space: unset;
  }
  &--info {
    margin-top: 0;
  }
}
