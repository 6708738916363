.closed-q {
  text-align: left;
  justify-content: center;
  font-weight: $normal;
  font-size: 1.6rem;
  line-height: 2.4rem;

  &--instruction {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--subjects {
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: unset;
  }
  &--time {
    width: 100%;
    font-size: 1.4rem;
    line-height: 0;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    &__icon {
      color: #616161;
      position: absolute;
      left: 0;
    }
  }
  &--mouse {
    width: 100%;
    text-align: center;
  }
  &--card {
    width: 100%;
    text-align: center;
  }
  &--icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.6rem;
    border-radius: 0.1rem;
    margin-left: 3rem;
    color: #616161;
    &--eye {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #616161;
      font-size: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      &:hover {
        color: #c4c4c4;
      }
    }
    &--file {
      background-image: url('../../../assets/icons/file-icon-dark.svg');
      background-repeat: no-repeat;
      width: 2.2rem;
      height: 2.2rem;
      background-size: cover;
      cursor: pointer;
      &:hover {
        background-image: url('../../../assets/icons/file-icon.svg');
      }
    }
    &--success {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background-color: #0fb474;
      border-radius: 0.4rem;
      width: 2rem;
      height: 2rem;
    }
    &--failure {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e06c76;
      border-radius: 0.4rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }
  .ant-table-cell {
    vertical-align: middle !important;
  }
}
