.custom-radio-group-container {
  display: flex;
  flex-direction: column;
}

.custom-radio-wrapper {
  display: flex;
  margin-top: 1.7rem;
}

.custom-radio-wrapper .ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio-inner {
  width: 2.4rem;
  height: 2.4rem;
  border: 0.2rem solid $hidden-text;
}
.ant-radio-inner:after {
  top: 0.6rem;
  left: 0.6rem;
}
.ant-radio-inner:checked {
  background-color: $primary !important;
}
.ant-radio-input {
  border-color: $primary !important ;
}
.ant-radio-input:checked {
  background-color: $primary !important ;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $hidden-text;
  box-shadow: none !important;
}

.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1.3);
  background-color: $lightGreen;
}

.ant-radio-checked::after {
  border: none;
}

.custom-radio-wrapper span {
  color: $silverChalice;
  font-size: 1.6rem !important;
}
