// ******************** INITIAL STATE ********************
.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 6rem;
  height: 100vh;
  background-color: $primaryBackgroud;
  overflow: hidden;
  transition: 0.4s;
  z-index: 2;
  &--button {
  margin-top: 3rem;
  }
}

.user-menu {
  margin-bottom: 3.6rem;
  background-color: inherit !important;
}

.app-menu-item-content {
  display: none;
}

.app-menu {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: inherit;

  &__item {
    @include flex();
    width: 17.6rem;
    height: 3.6rem;
    margin: 3.2rem 1.2rem 0 !important;
    padding-left: 0.2rem !important;
    transition: 0.4s;

    svg {
      font-size: 2.2rem;
      @include flex-center;
    }
  }

  &__item:nth-child(1) {
    margin-top: 2.1rem !important;
  }
}

.app-menu-item {
  height: 3.2rem !important;
  &__content {
    display: none;
    margin-left: 1.7rem;
    font-weight: normal;
    font-size: 1.6rem;
    width: 10rem;
    color: #bfbfbf;
    transition: 0.4s;
  }
}

.icon-container {
  @include flex-center;
  height: 3.2rem;
  width: 3.2rem;
}

.avatar {
  border: 0.2rem solid $white;
  border-radius: 0.8rem;
}

// ******************** HOVER STATE ********************

.sidebar:hover {
  width: 20rem;
  .app-menu-item-content {
    display: block;
  }
}

.app-menu-item:hover {
  background: $dark;
  border-radius: 0.4rem;
  .app-menu-item-content {
    color: $white;
  }
  svg path {
    fill: $primary !important;
  }
  .statistics-icon svg path {
    fill: $lightGreen !important;
  }
  .questions-icon svg path {
    fill: $primeRed !important;
  }
  .questions-icon svg path:nth-last-of-type(1) {
    fill: $primary !important;
  }
  .live-icon svg path:nth-last-of-type(1),
  .logout-icon svg path:nth-last-of-type(1) {
    fill: $lightGreen !important;
  }

  .live-icon svg path:nth-of-type(1) {
    fill: $lightGreen !important;
  }
}

// ******************** SELECTED STATE ********************

.ant-menu-item-selected {
  svg path {
    fill: $primary !important;
  }
  .questions-icon svg path:nth-last-of-type(1) {
    fill: $primary !important;
  }
  .questions-icon svg path {
    fill: $primeRed !important;
  }
  .live-icon svg path:nth-of-type(1) {
    fill: $lightGreen !important;
  }

  .live-icon svg path:nth-last-of-type(1) {
    fill: $lightGreen !important;
  }

  .logout-icon svg path:nth-last-of-type(1) {
    fill: $lightGreen !important;
  }

  .statistics-icon svg path {
    fill: $lightGreen !important;
  }
}
